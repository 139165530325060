.social-logins {
    max-width: 500px;
}

.social-title {
    font-size: large;
    margin: 0 10px 0 5px;
}

.social-user {
    font-weight: bold;
}

.social-badge {
    margin: 0 5px 0 10px;
}
