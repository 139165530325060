@media all and (min-width: 480px) {
    /* .Login {
      padding: 60px 0;
    } */
  
    /* .Login form {
      margin: 0 auto;
      max-width: 320px;
    } */

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }
    
    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }

    .forgot-login {
      font-weight: 600;
    }

  }